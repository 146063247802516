import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8-8\\@70% 1RM`}</p>
    <p>{`GHR’s 8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`20 Step Ups (10/leg, 24/20″) 25/15’s`}</p>
    <p>{`15 Wall Balls (20/14)`}</p>
    <p>{`10 KBS’s (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday will be our free Christmas Eve WOD, The 12 Days of
Christmas, at 10:00am at The Ville. All other classes are cancelled.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Personal Training, Elements, and Massage Gift Certificates now
available for the Holidays!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so get ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      